import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  showModalBackground: false,
  showModalImage: false,
  showModalVideo: false,
  showModalAudio: false,
  showModalTable: false,
  showModalQuiz: false,
  showModalCronometro: false,
  showModalButtonLink: false,
  showQuiz: false,
  showTalking: false,
  showConfig: false,
  fullScreen: false,
  showLayoutSelector: false,
  showGameToolsSelector: false,
  showTextTools: false,
  showModalControls: false,
  showToast: false,
  showModalAddCurso: false,
  showModalAddDivisao: false,
  showModalAddLivro: false,
  showModalAddItem: false,
  showModalEditAluno: false,
  showModalDeleteAluno: false,
  showJanelaDireita: false,
  titleJanelaDireita: 'Talking',
  showModalClickable: false,
  showImageConfig: false,
  showAudioConfig: false,
  showVideoConfig: false,
  showClickableConfig: false,
  showSlideConfig: false,
  showModalLayoutConfig: false,
  showModalExcluir: false,
  showBlankBoard: false,
  showCardToast: {
    show: false,
    text: '',
    type: 'danger',
    voice: false,
    points: 0,
    soundFeedback: true,
    timeoutToast: null
  },
  showSquareConfig: false,
  showPlacarTicTacToe: false,
  showTextConfig: false,
  showInputConfig: false,
  showTableConfig: false,
  showModalClonarAula: false,
  showGaleriaImagens: false,
  showGaleriaAvatares: false,
  showDiceConfig: false,
  showExtraWord: false,
  showLoading: false,
  showTotemConfig: false,
  showCronometroConfig: false,
  showGameCardConfig: false,
  showTotem: false,
  showDropableConfig: false,
  showRandomCardConfig: false,
  showPlacarConfig: false,
  showModalAddAula: false,
  showButtonLinkConfig: false,
  showLinkedCardRenderConfig: false,
  showSelectorConfig: false,
  showCollapsedTools: false,
  showGameLib: false,
  showHangmanConfig: false,
  showFoodGameConfig: false,
  showBasketballConfig: false,
  showGlobeGameConfig: false,
  showDarkRoomConfig: false,
  showMemoryGameConfig: false,
  showParkingGameConfig: false,
  showBagGameConfig: false,
  showSheepGameConfig: false,
  showZooGameConfig: false,
  showPrepositionGameConfig: false,
  showMonsterGameConfig: false,
  showFirefighterGameConfig: false,
  showDominoGameConfig: false,
  showSequenceImagesGameConfig: false,
  showWorkplaceGameConfig: false,
  showSnookerGameConfig: false,
  showGeniusGameConfig: false,
  showRockAiConfig: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.TOGGLE_SHOW_MODAL_BACKGROUND:
      return {
        ...state,
        showModalBackground: !state.showModalBackground
      };
    case actionsTypes.TOGGLE_SHOW_MODAL_IMAGE:
      return { ...state, showModalImage: !state.showModalImage };

    case actionsTypes.TOGGLE_SHOW_MODAL_VIDEO:
      return { ...state, showModalVideo: !state.showModalVideo };

    case actionsTypes.TOGGLE_SHOW_MODAL_AUDIO:
      return { ...state, showModalAudio: !state.showModalAudio };

    case actionsTypes.TOGGLE_SHOW_MODAL_TABLE:
      return { ...state, showModalTable: !state.showModalTable };

    case actionsTypes.TOGGLE_SHOW_MODAL_CRONOMETRO:
      return { ...state, showModalCronometro: !state.showModalCronometro };

    case actionsTypes.TOGGLE_SHOW_MODAL_BUTTON_LINK:
      return { ...state, showModalButtonLink: !state.showModalButtonLink };

    case actionsTypes.TOGGLE_FULL_SCREEN:
      return { ...state, fullScreen: !state.fullScreen, showBlankBoard: false };

    case actionsTypes.TOGGLE_SHOW_JANELA_DIREITA:
      return {
        ...state,
        showJanelaDireita: !state.showJanelaDireita,
        showQuiz: false,
        showTalking: false,
        showConfig: false,
        showImageConfig: false,
        showAudioConfig: false,
        showClickableConfig: false,
        showSlideConfig: false,
        showTextConfig: false,
        showInputConfig: false,
        showTableConfig: false,
        showVideoConfig: false,
        titleJanelaDireita: '',
        showDiceConfig: false,
        showTotemConfig: false,
        showCronometroConfig: false,
        showGameCardConfig: false,
        showSquareConfig: false,
        showPlacarTicTacToeConfig: false,
        showTotem: false,
        showDropableConfig: false,
        showRandomCardConfig: false,
        showPlacarConfig: false,
        showButtonLinkConfig: false,
        showSelectorConfig: false,
        showHangmanConfig: false,
        showFoodGameConfig: false,
        showBasketballConfig: false,
        showGlobeGameConfig: false,
        showDarkRoomConfig: false,
        showMemoryGameConfig: false,
        showParkingGameConfig: false,
        showBagGameConfig: false,
        showSheepGameConfig: false,
        showZooGameConfig: false,
        showPrepositionGameConfig: false,
        showMonsterGameConfig: false,
        showFirefighterGameConfig: false,
        showDominoGameConfig: false,
        showSequenceImagesGameConfig: false,
        showWorkplaceGameConfig: false,
        showSnookerGameConfig: false,
        showGeniusGameConfig: false
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_QUIZ:
      return {
        ...state,
        showQuiz: !state.showQuiz,
        titleJanelaDireita: 'Quiz'
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_TALKING:
      return {
        ...state,
        showTalking: !state.showTalking,
        titleJanelaDireita: 'Talking'
      };

    case actionsTypes.TOGGLE_SHOW_ROCK_AI_CONFIG:
      return {
        ...state,
        showRockAiConfig: !state.showRockAiConfig,
        titleJanelaDireita: 'Rock AI'
      };

    case actionsTypes.TOGGLE_SHOW_LAYOUT_SELECTOR:
      return { ...state, showLayoutSelector: !state.showLayoutSelector };

    case actionsTypes.TOGGLE_SHOW_GAMETOOLS_SELECTOR:
      return { ...state, showGameToolsSelector: !state.showGameToolsSelector };

    case actionsTypes.SET_SHOW_TEXT_TOOLS:
      return { ...state, showTextTools: action.show };

    case actionsTypes.TOGGLE_SHOW_MODAL_CONTROLS:
      return { ...state, showModalControls: !state.showModalControls };

    case actionsTypes.TOGGLE_SHOW_TOAST:
      return { ...state, showToast: !state.showToast };

    case actionsTypes.TOGGLE_SHOW_MODAL_ADD_CURSO:
      return { ...state, showModalAddCurso: !state.showModalAddCurso };
    case actionsTypes.TOGGLE_SHOW_MODAL_ADD_DIVISAO:
      return { ...state, showModalAddDivisao: !state.showModalAddDivisao };

    case actionsTypes.TOGGLE_SHOW_MODAL_ADD_LIVRO:
      return { ...state, showModalAddLivro: !state.showModalAddLivro };

    case actionsTypes.TOGGLE_SHOW_MODAL_ADD_ITEM:
      return { ...state, showModalAddItem: !state.showModalAddItem };

    case actionsTypes.TOGGLE_SHOW_MODAL_EDIT_ALUNO:
      return { ...state, showModalEditAluno: !state.showModalEditAluno };

    case actionsTypes.TOGGLE_SHOW_MODAL_DELETE:
      return { ...state, showModalDelete: !state.showModalDelete };

    case actionsTypes.TOGGLE_SHOW_MODAL_CLICKABLE:
      return { ...state, showModalClickable: !state.showModalClickable };

    case actionsTypes.TOGGLE_SHOW_IMAGE_CONFIG:
      return {
        ...state,
        showImageConfig: !state.showImageConfig,
        titleJanelaDireita: 'Imagem'
      };

    case actionsTypes.TOGGLE_SHOW_AUDIO_CONFIG:
      return {
        ...state,
        showAudioConfig: !state.showAudioConfig,
        titleJanelaDireita: 'Áudio'
      };

    case actionsTypes.TOGGLE_SHOW_VIDEO_CONFIG:
      return {
        ...state,
        showVideoConfig: !state.showVideoConfig,
        titleJanelaDireita: 'Vídeo'
      };

    case actionsTypes.TOGGLE_SHOW_CLICKABLE_CONFIG:
      return {
        ...state,
        showClickableConfig: !state.showClickableConfig,
        titleJanelaDireita: 'Clickable'
      };

    case actionsTypes.TOGGLE_SHOW_INPUT_CONFIG:
      return {
        ...state,
        showInputConfig: !state.showInputConfig,
        titleJanelaDireita: 'Campo de texto'
      };

    case actionsTypes.TOGGLE_SHOW_TEXT_CONFIG:
      return {
        ...state,
        showTextConfig: !state.showTextConfig,
        titleJanelaDireita: 'Texto'
      };

    case actionsTypes.TOGGLE_SHOW_TABLE_CONFIG:
      return {
        ...state,
        showTableConfig: !state.showTableConfig,
        titleJanelaDireita: 'Tabela'
      };

    case actionsTypes.TOGGLE_SHOW_SLIDE_CONFIG:
      return {
        ...state,
        showSlideConfig: !state.showSlideConfig,
        titleJanelaDireita: 'Configurações da aula e slide'
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_LAYOUT_CONFIG:
      return { ...state, showModalLayoutConfig: !state.showModalLayoutConfig };

    case actionsTypes.TOGGLE_SHOW_MODAL_EXCLUIR:
      return { ...state, showModalExcluir: !state.showModalExcluir };

    case actionsTypes.TOGGLE_SHOW_BLANK_BOARD:
      return { ...state, showBlankBoard: !state.showBlankBoard };

    case actionsTypes.SET_SHOW_CARD_TOAST:
      return {
        ...state,
        showCardToast: {
          ...state.showCardToast,
          show: action.show,
          text: action.text,
          type: action.tipo,
          voice: action.voice,
          points: action.points,
          soundFeedback: action.soundFeedback,
          timeoutToast: action.timeoutToast
        }
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_CLONAR_AULA:
      return {
        ...state,
        showModalClonarAula: !state.showModalClonarAula
      };

    case actionsTypes.TOGGLE_SHOW_GALERIA_IMAGENS:
      return { ...state, showGaleriaImagens: !state.showGaleriaImagens };

    case actionsTypes.TOGGLE_SHOW_GALERIA_AVATARES:
      return { ...state, showGaleriaAvatares: !state.showGaleriaAvatares };

    case actionsTypes.TOGGLE_SHOW_DICE_CONFIG:
      return {
        ...state,
        showDiceConfig: !state.showDiceConfig,
        titleJanelaDireita: 'Dado'
      };
    case actionsTypes.TOGGLE_SHOW_GAME_CARD_CONFIG:
      return {
        ...state,
        showGameCardConfig: !state.showGameCardConfig,
        titleJanelaDireita: 'Card'
      };
    case actionsTypes.TOGGLE_SHOW_TOTEM_CONFIG:
      return {
        ...state,
        showTotemConfig: !state.showTotemConfig,
        titleJanelaDireita: 'Totem'
      };
    case actionsTypes.TOGGLE_SHOW_PLACAR_CONFIG:
      return {
        ...state,
        showPlacarConfig: !state.showPlacarConfig,
        titleJanelaDireita: 'Placar'
      };

    case actionsTypes.TOGGLE_SHOW_BUTTON_LINK_CONFIG:
      return {
        ...state,
        showButtonLinkConfig: !state.showButtonLInkConfig,
        titleJanelaDireita: 'Button Link'
      };

    case actionsTypes.TOGGLE_SHOW_EXTRA_WORD:
      return { ...state, showExtraWord: !state.showExtraWord };

    case actionsTypes.TOGGLE_SHOW_CRONOMETRO_CONFIG:
      return {
        ...state,
        showCronometroConfig: !state.showCronometroConfig,
        titleJanelaDireita: 'Cronômetro'
      };
    case actionsTypes.TOGGLE_SHOW_RANDOMCARD_CONFIG:
      return {
        ...state,
        showRandomCardConfig: !state.showRandomCardConfig,
        titleJanelaDireita: 'Random Card'
      };

    case actionsTypes.TOGGLE_SHOW_TOTEM:
      return {
        ...state,
        showTotem: !state.showTotem,
        titleJanelaDireita: 'Totem'
      };
    case actionsTypes.TOGGLE_SHOW_LOADING:
      return { ...state, showLoading: !state.showLoading };

    case actionsTypes.TOGGLE_SHOW_DROPABLE_CONFIG:
      return {
        ...state,
        showDropableConfig: !state.showDropableConfig,
        titleJanelaDireita: 'Dropable'
      };

    case actionsTypes.TOGGLE_SHOW_MODAL_ADD_AULA:
      return { ...state, showModalAddAula: !state.showModalAddAula };

    case actionsTypes.TOGGLE_SHOW_MODAL_SELECT_SLIDE:
      return { ...state, showModalSelectSlide: !state.showModalSelectSlide };

    case actionsTypes.TOGGLE_SHOW_SELECTOR_CONFIG:
      return {
        ...state,
        showSelectorConfig: !state.showSelectorConfig,
        titleJanelaDireita: 'Seletor'
      };

    case actionsTypes.RESET_SHOW_CONFIG:
      return {
        ...state,
        showQuiz: false,
        showTalking: false,
        showConfig: false,
        showImageConfig: false,
        showAudioConfig: false,
        showClickableConfig: false,
        showSlideConfig: false,
        showTextConfig: false,
        showInputConfig: false,
        showTableConfig: false,
        showVideoConfig: false,
        titleJanelaDireita: '',
        showDiceConfig: false,
        showTotemConfig: false,
        showCronometroConfig: false,
        showGameCardConfig: false,
        showSquareConfig: false,
        showPlacarTicTacToeConfig: false,
        showTotem: false,
        showDropableConfig: false,
        showRandomCardConfig: false,
        showPlacarConfig: false,
        showButtonLinkConfig: false,
        showSelectorConfig: false,
        showHangmanConfig: false,
        showFoodGameConfig: false,
        showBasketballConfig: false,
        showGlobeGameConfig: false,
        showDarkRoomConfig: false,
        showMemoryGameConfig: false,
        showParkingGameConfig: false,
        showSheepGameConfig: false,
        showZooGameConfig: false,
        showBagGameConfig: false,
        showPrepositionGameConfig: false,
        showMonsterGameConfig: false,
        showFirefighterGameConfig: false,
        showDominoGameConfig: false,
        showSequenceImagesGameConfig: false,
        showWorkplaceGameConfig: false,
        showSnookerGameConfig: false,
        showGeniusGameConfig: false
      };

    case actionsTypes.TOGGLE_SHOW_COLLAPSED_TOOLS:
      return {
        ...state,
        showCollapsedTools:
          action.show !== null ? action.show : !state.showCollapsedTools
      };

    case actionsTypes.TOGGLE_SHOW_GAME_LIB:
      return {
        ...state,
        showGameLib: !state.showGameLib
      };

    case actionsTypes.TOGGLE_SHOW_HANGMAN_CONFIG:
      return {
        ...state,
        showHangmanConfig: !state.showHangmanConfig,
        titleJanelaDireita: 'Jogo da forca'
      };

    case actionsTypes.TOGGLE_SHOW_GLOBE_GAME_CONFIG:
      return {
        ...state,
        showGlobeGameConfig: !state.showGlobeGameConfig,
        titleJanelaDireita: 'Jogo do Globo'
      };

    case actionsTypes.TOGGLE_SHOW_FOOD_GAME_CONFIG:
      return {
        ...state,
        showFoodGameConfig: !state.showFoodGameConfig,
        titleJanelaDireita: 'Food Game'
      };
    case actionsTypes.TOGGLE_SHOW_BASKETBALL_CONFIG:
      return {
        ...state,
        showBasketballConfig: !state.showBasketballConfig,
        titleJanelaDireita: 'Basquete'
      };
    case actionsTypes.TOGGLE_SHOW_DARK_ROOM_CONFIG:
      return {
        ...state,
        showDarkRoomConfig: !state.showDarkRoomConfig,
        titleJanelaDireita: 'Quarto Escuro'
      };

    case actionsTypes.TOGGLE_SHOW_MEMORY_GAME_CONFIG:
      return {
        ...state,
        showMemoryGameConfig: !state.showMemoryGameConfig,
        titleJanelaDireita: 'Jogo da Memória'
      };

    case actionsTypes.TOGGLE_SHOW_PARKING_GAME_CONFIG:
      return {
        ...state,
        showParkingGameConfig: !state.showParkingGameConfig,
        titleJanelaDireita: 'Jogo da Garagem'
      };

    case actionsTypes.TOGGLE_SHOW_ZOO_GAME_CONFIG:
      return {
        ...state,
        showZooGameConfig: !state.showZooGameConfig,
        titleJanelaDireita: 'Zoo Game'
      };

    case actionsTypes.TOGGLE_SHOW_BAG_GAME_CONFIG:
      return {
        ...state,
        showBagGameConfig: !state.showBagGameConfig,
        titleJanelaDireita: 'Jogo da Mochila'
      };

    case actionsTypes.TOGGLE_SHOW_SHEEP_GAME_CONFIG:
      return {
        ...state,
        showSheepGameConfig: !state.showSheepGameConfig,
        titleJanelaDireita: 'Jogo da Ovelha'
      };

    case actionsTypes.TOGGLE_SHOW_PREPOSITION_GAME_CONFIG:
      return {
        ...state,
        showPrepositionGameConfig: !state.showPrepositionGameConfig,
        titleJanelaDireita: 'Jogo das Preposições'
      };

    case actionsTypes.TOGGLE_SHOW_MONSTER_GAME_CONFIG:
      return {
        ...state,
        showMonsterGameConfig: !state.showMonsterGameConfig,
        titleJanelaDireita: 'Jogo do Monstro'
      };

    case actionsTypes.TOGGLE_SHOW_FIREFIGHTER_GAME_CONFIG:
      return {
        ...state,
        showFirefighterGameConfig: !state.showFirefighterGameConfig,
        titleJanelaDireita: 'Jogo do bombeiro'
      };

    case actionsTypes.TOGGLE_SHOW_SQUARE_CONFIG:
      return {
        ...state,
        showSquareConfig: !state.showSquareConfig,
        titleJanelaDireita: 'Quadrado Tic Tac Toe'
      };

    case actionsTypes.TOGGLE_SHOW_PLACAR_TIC_TAC_TOE_CONFIG:
      return {
        ...state,
        showPlacarTicTacToeConfig: !state.showPlacarTicTacToeConfig,
        titleJanelaDireita: 'Placar Tic Tac Toe'
      };

    case actionsTypes.TOGGLE_SHOW_DOMINO_GAME_CONFIG:
      return {
        ...state,
        showDominoGameConfig: !state.showDominoGameConfig,
        titleJanelaDireita: 'Dominó'
      };

    case actionsTypes.TOGGLE_SHOW_SEQUENCE_IMAGES_GAME_CONFIG:
      return {
        ...state,
        showSequenceImagesGameConfig: !state.showSequenceImagesGameConfig,
        titleJanelaDireita: 'Sequência de Imagens'
      };

    case actionsTypes.TOGGLE_SHOW_WORKPLACE_GAME_CONFIG:
      return {
        ...state,
        showWorkplaceGameConfig: !state.showWorkplaceGameConfig,
        titleJanelaDireita: 'Workplace'
      };

    case actionsTypes.TOGGLE_SHOW_SNOOKER_GAME_CONFIG:
      return {
        ...state,
        showSnookerGameConfig: !state.showSnookerGameConfig,
        titleJanelaDireita: 'Sinuca'
      };

    case actionsTypes.TOGGLE_SHOW_GENIUS_GAME_CONFIG:
      return {
        ...state,
        showGeniusGameConfig: !state.showGeniusGameConfig,
        titleJanelaDireita: 'Jogo do Gênio'
      };

    default:
      return state;
  }
}
